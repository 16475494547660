@mixin baton-font($base-url: '') {
  @font-face {
    font-family: BatonSubset;
    font-style: normal;
    font-weight: 900;
    src: url('#{$base-url}/assets/webfonts/baton/baton-black-subset.woff')
      format('woff');
  }

  @font-face {
    font-family: Baton;
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('#{$base-url}/assets/webfonts/baton/baton-black.woff')
      format('woff');
  }

  .fonts-stage-1 .display-heading-font-family {
    font-family: BatonSubset, sans-serif;
  }

  .fonts-stage-2 .display-heading-font-family {
    font-family: Baton, sans-serif;
  }
}
