@use 'sass:color';

.alert {
  &-danger {
    background: color.adjust($danger, $lightness: 45);
  }

  &-info {
    background: $light;
  }

  &-success {
    background: color.adjust($success, $lightness: 50);
  }
}
