.btn {
  box-shadow: unset;

  &:focus,
  &:active:focus {
    box-shadow: none;
    outline: none;
  }

  &.btn-outline-white {
    background-color: transparent;
    border-color: $white;
    color: $white;

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      background-color: transparent;
      border-color: $white;
      color: $white;
    }
  }

  &.btn-orange {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    &:hover:not(:disabled),
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      background-color: $orange !important;
      border-color: $orange !important;
    }
  }

  &.btn-link {
    text-decoration: underline;
  }

  &.btn-sm {
    font-size: $font-size-base;
  }

  &.btn-outline-secondary {
    --btn-hover-bg: transparent;
    --btn-active-bg: transparent;
    /* stylelint-disable */
    @extend .btn-link;
    /* stylelint-enable */
    padding-left: 0;
  }

  &:disabled {
    background-color: $gray-400;
    border-color: $gray-400;
  }
}
