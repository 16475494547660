$fontello-icons: (
  'check': (
    '\e801',
    currentColor,
    before,
  ),
  'check-success': (
    '\e801',
    $success,
    before,
  ),
  'check-white': (
    '\e801',
    $white,
    before,
  ),
  'underlined-links-angle-right': (
    '\41',
    $primary,
    after,
  ),
  'underlined-links-grey-line-blue-angle-right': (
    '\41',
    $primary,
    after,
  ),
  'cross': (
    '\54',
    $danger,
    before,
  ),
  'bolt': (
    '\e0b7',
    currentColor,
    before,
  ),
  'tag': (
    '\f02b',
    currentColor,
    before,
  ),
  'euro-sign': (
    '\f153',
    currentColor,
    before,
  ),
  'info-circle': (
    '\f05a',
    currentColor,
    before,
  ),
  'chevron-up': (
    '\f077',
    currentColor,
    before,
  ),
  'chevron-down': (
    '\f078',
    currentColor,
    before,
  ),
  'exclamation-circle': (
    '\0045',
    currentColor,
    before,
  ),
);

$icon-font-family: 'fontello-light';
