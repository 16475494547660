// Links
//
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: underline !default;
$link-hover-color: shift-color($link-color, 20%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;
