@use 'sass:map';

// Color system

$white: #fff !default;
$gray-100: #f9f9f9 !default;
$gray-200: #f1f1f1 !default;
$gray-300: #ccc !default;
$gray-400: #ccc !default;
$gray-500: #94949e !default;
$gray-600: #434751 !default;
$gray-700: #434751 !default;
$gray-800: #333 !default;
$gray-900: #333 !default;
$black: #000 !default;

$grays: () !default;
$grays: map.merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #296aff !default;
$bordeaux: #aa014c !default;
$pink: #e60167 !default;
$red: #e60101 !default;
$yellow: #fa0 !default;
$orange: #f60 !default;
$orange-light: #ffc299 !default;
$green: #393 !default;
$green-variant-2: #267326 !default;
$green-variant-3: #a3e0a3 !default;
$green-light: #d4edda !default;
$jagged-ice: #cce6ec !default;

$colors: () !default;
$colors: map.merge(
  (
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'green': $green,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$brand: $pink !default;
$brand-dark: $bordeaux !default;
$success: $green !default;
$info: $jagged-ice !default;
$warning: $orange !default;
$danger: $red !default;
$light: $gray-200 !default;
$lighter: $gray-400 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map.merge(
  (
    'primary': $primary,
    'sales-primary': $primary,
    'secondary': $secondary,
    'sales-secondary': $secondary,
    'brand': $brand,
    'brand-dark': $brand-dark,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$color-contrast-dark: $gray-900 !default;
$color-contrast-light: $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29')
) !default;
