$accordion-padding-y: 1rem !default;
$accordion-padding-x: 1rem !default;
$accordion-color: $body-color !default;
$accordion-bg: $body-bg !default;
$accordion-border-width: $border-width !default;
$accordion-border-color: $gray-400 !default;
$accordion-border-radius: $border-radius !default;
$accordion-inner-border-radius: subtract(
  $accordion-border-radius,
  $accordion-border-width
) !default;

$accordion-body-padding-y: $accordion-padding-y !default;
$accordion-body-padding-x: $accordion-padding-x !default;

$accordion-button-padding-y: $accordion-padding-y !default;
$accordion-button-padding-x: $accordion-padding-x !default;
$accordion-button-color: inherit !default;
$accordion-button-bg: inherit !default;
$accordion-transition:
  $btn-transition,
  border-radius 0.15s ease !default;
$accordion-button-active-bg: inherit !default;
$accordion-button-active-color: inherit !default;

$accordion-button-focus-border-color: $input-focus-border-color !default;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow !default;

$accordion-icon-width: 1.25rem !default;
$accordion-icon-color: $dark !default;
$accordion-icon-color-light: $white !default;
$accordion-icon-active-color: $dark !default;
$accordion-icon-active-color-light: $white !default;
$accordion-icon-transition: transform 0.2s ease-in-out !default;
$accordion-icon-transform: rotate(-180deg) !default;

$accordion-button-icon: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
) !default;
$accordion-button-active-icon: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
) !default;

// Added this light icon because 'fill' via CSS does not work as svg is set via background-image... It is part of the .accordion-header--dark class
$accordion-button-icon-light: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color-light}'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
) !default;
$accordion-button-active-icon-light: escape-svg(
  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color-light}'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>")
) !default;
