.mijn {
  h1 {
    font-size: $h2-font-size;
  }

  h2 {
    font-size: $h3-font-size;
  }

  h3,
  h4,
  h5 {
    font-size: 1.125rem;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  &.display-heading-font-family {
    line-height: 1;
  }
}

.microcopy {
  font-size: 0.875rem;
}
