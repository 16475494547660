// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.7rem !default;
$input-btn-padding-x: 1rem !default;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0 !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-box-shadow: 0 0 0 0 $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.375rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 1rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width !default;
