// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

@include _assert-ascending($container-max-widths, '$container-max-widths');
