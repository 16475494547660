.d-essent {
  display: inline-block;
}

.d-essent-block {
  display: block;
}

@each $index, $value in $grid-breakpoints {
  @media (min-width: $value) {
    .d-#{$index}-essent {
      display: inline-block;
    }
  }
}
