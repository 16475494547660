@use 'sass:math';
@use 'sass:map';

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map.merge(
  (
    0: 0,
    '2-3': math.div($spacer, 3),
    1: $spacer * 0.5,
    2: $spacer * 1,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 2.5,
    6: $spacer * 3,
    8: $spacer * 4,
    10: $spacer * 5,
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
$sizes: map.merge(
  (
    25: 25%,
    30: 30%,
    50: 50%,
    75: 75%,
    90: 90%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);
