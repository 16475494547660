@each $index, $value in $spacers {
  .ms-essent-#{$index} {
    margin-left: $value;
  }

  .mx-essent-#{$index} {
    margin: {
      margin-right: $value;
      margin-left: $value;
    }
  }

  .my-essent-#{$index} {
    margin: {
      top: $value;
      bottom: $value;
    }
  }

  .mb-essent-#{$index} {
    margin-bottom: $value;
  }
}
