// Creates a wrapper for floating labels, compatible with Bootstrap 4
.floating-label {
  position: relative;
  width: 100%;

  %focussed-label {
    top: $input-padding-y * 0.75;
    color: $dark;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
  }

  label {
    // Positions the label on top of the input.
    position: absolute;
    top: $input-padding-y * $line-height-base;
    left: $input-padding-x-md;
    color: $gray-500;
    line-height: 1;
    font-size: $font-size-base;
    transition: all 0.2s;
    cursor: text;
    pointer-events: none;
    z-index: 3;
    user-select: none;

    @include media-breakpoint-up(sm) {
      left: $input-padding-x;
    }

    &.fixed-padding {
      left: $input-padding-x;
    }

    &.focussed {
      @extend %focussed-label;
    }
  }

  .form-control {
    padding: $input-padding-y $input-padding-x-md 0 $input-padding-x-md;

    box-shadow: none;
    transition: all 0.2s;

    @include media-breakpoint-up(sm) {
      padding: $input-padding-y $input-padding-x 0 $input-padding-x;
    }

    &.has-value ~ label,
    &:focus ~ label {
      @extend %focussed-label;
    }
  }
}

// Validation
.is-invalid,
.ng-dirty.ng-invalid,
.ng-touched.ng-pristine.ng-invalid {
  color: $dark;

  &:not([type='checkbox']):not([type='radio']),
  .input-group-text {
    border-bottom-color: $danger !important;
    border-bottom-width: $input-border-validation-width;
  }

  &[type='checkbox'],
  &[type='radio'] {
    border-color: $danger;
  }
}

.is-valid,
.ng-dirty.ng-valid {
  color: $dark;

  &:not([type='checkbox']):not([type='radio']),
  .input-group-text {
    border-bottom-color: $success !important;
    border-bottom-width: $input-border-validation-width;
  }
}

.is-warning {
  border-bottom-color: $warning !important;
  border-bottom-width: $input-border-validation-width;
}

.change-input {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 66.66%;
  }

  &-label {
    font-size: 1rem;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
      font-weight: bolder;
    }
  }
}

.form-error {
  font-style: italic;
}

.ng-select {
  color: $gray-900;

  .ng-select-container.ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      border-color: $input-focus-border-color;
    }
  }

  &.is-valid {
    .ng-select-container {
      border-bottom-color: $success;
      border-bottom-width: $input-border-validation-width;
    }

    &.ng-select-opened {
      .ng-dropdown-panel-items {
        border-top-color: $success;
        border-top-width: $input-border-validation-width;
      }
    }
  }

  &.is-invalid {
    .ng-select-container {
      border-bottom-color: $danger;
      border-bottom-width: $input-border-validation-width;
    }

    &.ng-select-opened {
      .ng-dropdown-panel-items {
        border-top-color: $danger;
        border-top-width: $input-border-validation-width;
      }
    }
  }
}

.custom-control.custom-checkbox {
  padding-left: 2rem;

  .custom-control-label::before {
    left: -2rem;
  }

  .custom-control-label::after {
    left: -2rem;
  }
}
