ol.list-group-numbered {
  > li.list-group-item {
    margin-block: $list-item-spacing;
    display: flex;

    &::before {
      content: counter(listItemCounter);
      display: flex;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      flex-shrink: 0;
      background-color: $brand;
      border-radius: 100%;
      color: $white;
      font-size: 0.8rem;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
  }
}
